import React, { useEffect } from "react"
import { useRecoilState } from "recoil"
import { graphql } from "gatsby"
import { navigate } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { IsMobileDevice } from "../utils/functions"
import ThankYouHero from "../components/thank-you-hero"
import Seo from "../components/seo"
import { isMobileAtom } from "../recoil/atoms.js"

import { window, document } from "browser-monads"

const ThankYouPage = () => {
  const [isMobile, setIsMobile] = useRecoilState(isMobileAtom)

  useEffect(() => {
    setIsMobile(IsMobileDevice())
  }, [])

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--vh",
      `${window.innerHeight / 100}px`
    )
  }, [])

  useEffect(() => {
    let local = localStorage.getItem("age")
    let session = sessionStorage.getItem("age")
    if (!local && !session)
      navigate("/agegate", {
        state: {
          newPath: "/thank-you",
        },
      })
  }, [])

  return (
    <>
      <Seo title="Stella Artois" />
      <ThankYouHero isMobile={isMobile} />
    </>
  )
}

export default ThankYouPage