import React from "react"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import star from "../svg/bocado-star.svg"
import styles from "../styles/thank-you-hero.module.scss"

const ThankYouHero = ({ isMobile }) => {
  return (
    <div className={styles.thankYouHeroContainer}>
      <img src={star} alt="Stella Artois" className={styles.thankYouHeroStar} />
      <div className={styles.thankYouHeroImageContainer}>
        {isMobile ? (
          <StaticImage
            src="../images/ThankYou/01mobile.jpg"
            height={1080}
            quality={95}
            formats={["AUTO"]}
            placeholder="blurred"
            alt="Stella Artois"
            className={styles.thankYouHeroImage}
          />
        ) : (
          <StaticImage
            src="../images/ThankYou/01.jpg"
            height={1080}
            quality={95}
            formats={["AUTO"]}
            placeholder="blurred"
            alt="Stella Artois"
            className={styles.thankYouHeroImage}
          />
        )}
      </div>
      <div className={styles.thankYouHeroRightHalf}>
        <div className={styles.thankYouHeroTextContainer}>
          <div className={styles.thankYouHeroTitle}>
            ¡Muchas gracias por tu compra!
          </div>
          <button
            className={styles.thankYouHeroButton}
            onClick={() => navigate("/")}
          >
            Volver al inicio
          </button>
        </div>
      </div>
    </div>
  )
}

export default ThankYouHero
